<template>
  <img v-if="SVG" :data-src="image.url" :alt="image.alt ? image.alt : ''" />
  <div v-else-if="images.length > 0" class="lazy-image-container">
    <picture
      :id="lazyImageId"
      ref="picture"
      :data-alt="image.alt ? image.alt : ''"
      class="lazy-image"
      :class="{
        loaded: !loading || forceShow,
        ready: ready,
        'no-lazy': forceShow,
      }"
      :style="style"
    >
      <source
        v-for="(img, index) in images"
        :key="img.image.url + index"
        :srcset="getSrcSet(img.image, img.widths || [], img.imageCdn)"
        :media="
          img.media.type === 'min-max'
            ? `(min-width: ${img.media.min}px) and (max-width: ${img.media.max}px)`
            : `(${img.media.type}-width: ${img.media.value}px)`
        "
        :sizes="img.sizes"
      />
      <img v-if="forceShow" :alt="image.alt ? image.alt : ''" />
    </picture>

    <div
      v-if="imagesHasMq"
      :id="`lazyImageStyle-${_uid}`"
      v-html="imagesStyle"
    />
  </div>
  <div v-else-if="image && image.url" class="lazy-image-container">
    <picture
      ref="picture"
      :data-alt="image.alt ? image.alt : ''"
      class="lazy-image"
      :class="{ loaded: !loading, ready: ready }"
      :style="style"
    >
      <source :srcset="imageSrcSet" :sizes="sizes" />
      <img v-if="forceShow" :alt="image.alt ? image.alt : ''" />
    </picture>
  </div>
</template>

<script>
import lozad from 'lozad-new'
import NuxtSSRScreenSize from 'nuxt-ssr-screen-size'

import {
  imageSrcSet,
  fromPrismicImageConfToBunnyImageLink,
  bunnyImageSrcSet,
} from '@/utils/imageSize'

export default {
  name: 'LazyImage',
  mixins: [NuxtSSRScreenSize.NuxtSSRScreenSizeMixin],
  props: {
    provider: {
      type: String,
      default: 'prismic',
    },
    image: {
      type: Object,
      default: () => ({
        url: '',
        alt: '',
        dimentions: {
          width: 1,
          height: 1,
        },
      }),
    },
    imageCdn: {
      type: String,
      default: null,
    },
    images: {
      type: Array,
      default: () => [],
    },
    sizes: {
      type: String,
      default: '100vw',
    },
    widths: {
      type: Array,
      default: () => [],
    },
    lqip: {
      type: Boolean,
      default: true,
    },
    blur: {
      type: Boolean,
      default: false,
    },
    role: {
      type: String,
      default: '',
    },
    forceShow: {
      type: Boolean,
      default: false,
    },
    preload: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: !this.forceShow,
      ready: this.forceShow,
      observer: null,
    }
  },
  head() {
    if (this.preload) {
      if (this.images.length > 0) {
        const links = []
        for (const img of this.images) {
          links.push({
            rel: 'preload',
            as: 'image',
            href: this.imageCdn
              ? fromPrismicImageConfToBunnyImageLink(this.imageCdn, img.image)
              : img.image.url,
            imagesrcset: this.getSrcSet(
              img.image,
              img.widths || [],
              img.imageCdn
            ),
            imagesizes: img.sizes,
            media:
              img.media.type === 'min-max'
                ? `(min-width: ${img.media.min}px) and (max-width: ${img.media.max}px)`
                : `(${img.media.type}-width: ${img.media.value}px)`,
          })
        }
        return { link: links }
      }
      return {
        link: [
          {
            rel: 'preload',
            href: this.imageCdn
              ? fromPrismicImageConfToBunnyImageLink(this.imageCdn, this.image)
              : this.image.url,
            imagesrcset: this.imageSrcSet,
            imagesizes: this.sizes,
            as: 'image',
          },
        ],
      }
    }
    return {}
  },
  computed: {
    SVG() {
      if (this.image && this.image.url) {
        const img = new URL(this.image.url)
        if (img.href.includes('.svg')) {
          return true
        }
      }
      return false
    },
    imageSrcSet() {
      if (this.image && this.image.url) {
        if (this.SVG) {
          return this.image.url
        }

        return this.getSrcSet(this.image, this.widths, this.imageCdn)
      }
      return ''
    },
    imagesHasMq() {
      return this.images.length > 0 && this.images.some((img) => img.media)
    },
    style() {
      if (this.imagesHasMq) {
        return
      }
      if (!this.image.dimensions.width || !this.image.dimensions.height) {
        return this.SVG ? '' : `--padding-top: 100%;`
      }
      return this.SVG
        ? ''
        : `--padding-top: ${
            (this.image.dimensions.height / this.image.dimensions.width) * 100
          }%;`
    },
    lazyImageId() {
      return `lazyImage-${this._uid}`
    },
    imagesStyle() {
      if (this.imagesHasMq) {
        let style = []

        for (const img of this.images) {
          if (img.media.type === 'min-max' && img.media.min && img.media.max) {
            const top =
              (img.image.dimensions.height / img.image.dimensions.width) * 100
            style.push(
              `@media screen and (min-width: ${img.media.min}px) and (max-width: ${img.media.max}px) { #${this.lazyImageId} { --padding-top: ${top}%; }}`
            )
          }
          if (img.media.type === 'max' && img.media.value) {
            const top =
              (img.image.dimensions.height / img.image.dimensions.width) * 100
            style.push(
              `@media screen and (max-width: ${img.media.value}px) { #${this.lazyImageId} { --padding-top: ${top}%; }}`
            )
          }
          if (img.media.type === 'min' && img.media.value) {
            const top =
              (img.image.dimensions.height / img.image.dimensions.width) * 100
            style.push(
              `@media screen and (min-width: ${img.media.value}px) { #${this.lazyImageId} { --padding-top: ${top}%; }}`
            )
          }
        }

        style = style.join('\n')

        return `<style>${style}</style>`
      }

      return ''
    },
  },
  mounted() {
    if (!this.forceShow && (this.image.url || this.images.length > 0)) {
      const rootMargin = `${window.innerHeight * (50 / 100)}px`
      this.observer = lozad(this.SVG ? this.$el : this.$refs.picture, {
        rootMargin,
        loaded: (el) => {
          this.loading = false
          if (!this.SVG && this.$refs.picture) {
            const img = el.getElementsByTagName('img')[0]
            img.alt = this.alt ? this.alt : ''
            img.role = this.role
            if (img.complete && img.naturalHeight !== 0) {
              this.ready = true
              this.$refs.picture.dispatchEvent(new Event('loaded'))
              this.$emit('loaded')
            } else {
              const picture = this.$refs.picture
              img.addEventListener('load', () => {
                this.ready = true
                picture.dispatchEvent(new Event('loaded'))
                this.$emit('loaded')
              })
            }
          }
        },
      })
      this.observer.observe()
    }
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.observer.disconnect()
    }
  },
  methods: {
    getSrcSet(img, widths = [], imgCdn = null) {
      if (img.url) {
        if (this.provider === 'shopify') {
          return `${img.url} 1x, ${img.url_2x} 2x`
        }

        if (this.provider === 'bunny') {
          return bunnyImageSrcSet(img.url, img.dimensions)
        }

        return imageSrcSet(img, widths, imgCdn)
      }
      return ''
    },
  },
}
</script>

<style lang="scss">
.lazy-image-container {
  background-color: $secondary-color-4;
  overflow: hidden;
}

.lazy-image {
  width: 100%;
  height: 0;
  background-color: $secondary-color-4;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  transition: all 0.3s;
  padding-top: var(--padding-top, 100%);

  &:not(.loaded) {
    overflow: hidden;

    @include skeleton;
  }

  &__wrapper {
    position: relative;
    z-index: 200;
  }

  & > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &.no-lazy {
    > img {
      animation: none;
    }
  }
}
</style>
